<template>
  <div>
    <!-- <feather-icon
        class="text-body"
        icon="BellIcon"
        size="21"
        @click="openList()"
        disabled="loading"
    /> -->
    <div class="notifications-container-viewing" v-if="viewing">
        <div>
          <div class="flex-flags" v-if="!!isAdmin">
              <span style="font-size: 1rem; font-weight: bold;" v-if="displayedNotification.country.code === 'all'">{{ displayedNotification.country.text }}</span>
              <img class="styles-flags" style="margin-right: 0;" v-if="displayedNotification.country.code !== 'all'" :src="require(`@/assets/images/flags/${displayedNotification.country.code.toLowerCase()}.png`)" :alt="displayedNotification.country.text">
          </div>
          <div class="scrollable-notification">
            <div v-html="displayedNotification.content"></div>
          </div>
        </div>
        <div class="actions padding-top-popup">
          <b-button @click="prev" v-if="showPrev()" variant="success" class="mr-10 prev float-mobile-left"><i class="fas fa-chevron-left"></i> &nbsp; Anterior </b-button>  
          <b-button @click="finish" v-if="showClose()" variant="danger" class="push-right float-mobile-left">Cerrar</b-button>
          <b-button @click="finish" v-if="showCloseAdmin() && !!isAdmin" class="center-button-all-close" variant="danger">Cerrar todos</b-button>
          <b-button @click="next" v-if="showNext()" variant="success" class="push-right float-mobile-left">Siguiente &nbsp; <i class="fas fa-chevron-right"></i></b-button>
        </div>
    </div>
    <div class="notifications-container-listing" v-if="listing">
      <div :key="keyListing">
            <div class="flex-header">
                <h5 class="notification-list-title">Notificaciones</h5>
                <button @click="close" class="close">x</button>
            </div>
            <div class="filter-notifications">
                <div>
                  <b-dropdown id="dropdown-filter" :class="activeBtnFilter ? 'active' : ''" toggle-class="color-personalize-btn-filter" variant="outline-light" menu-class="list-options-filter" no-caret>
                    <template #button-content>
                      <feather-icon
                          icon="FilterIcon"
                          size="20"
                      />
                    </template>
                    <b-dropdown-form form-class="personalize-form">
                      <form-render
                      ref="formRenderCheck" :key="keyFormRenderCheck" :fields="fieldsCheck" :form.sync="formFilterCheck" 
                      />
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              <form-render
              ref="formRender" :key="keyFormRender" class="px-0 col-12 field-filter" :fields="fields" :form.sync="form"
              />
            </div>
            <div class="margin-select-filters">
              <form-render
                  ref="formRenderFilter" :key="keyFormRenderFilter" :fields="fieldsFilter" :form.sync="formFilter" 
              >
              <template #buttons>
                <b-button v-if="showCleanFilter" variant="outline-light" class="ml-2" v-b-tooltip.hover title="Limpiar filtros" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
              </template>
              </form-render>
             </div>
            <ul class="notification-list">
                <li v-for="notification in notificationsList" :key="notification.id">
                  <div class="mb-badge">
                    <span v-if="!!notification.category">
                      <b-badge pill :variant="notification.category.color" class="personalize-badge">
                        <span >{{ notification.category.text }}</span>
                      </b-badge>
                    </span>
                    <small class="personalize-created">{{notification.created_date}}</small>
                  </div>
                  <h2 class="mb-1">{{notification.title}} </h2>      
                  <p class="mb-2">{{ notification.cleanContent }}</p>
                  <div class="personalize-footer mb-1">
                    <p @click="openFromList(notification.id)" class="show-modal-noti">Leer más 
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </p>
                    <div class="flex-flags-admin">
                      <div v-if="!!isAdmin">
                        <img class="styles-flags" v-if="notification.country.code !== 'all'" :src="require(`@/assets/images/flags/${notification.country.code.toLowerCase()}.png`)" alt="">
                      </div>
                      <div v-if="notification.carriers.length > 0">
                        <img :src="!!notification.carriers[0].logo ? notification.carriers[0].logo : require('@/assets/images/logo/logo-oficial-envíame.png')" :class="!!notification.carriers[0].logo ? 'styles-img-carriers' : 'img-logos-width'" alt=""/>
                      </div>
                      <div v-if="notification.carriers.length === 0">
                        <img class="img-logos-width" :src="require('@/assets/images/logo/logo-oficial-envíame.png')" alt=""/>
                      </div>
                    </div>
                  </div>
                </li>
                <div class="flex-btn-more-noti">
                  <b-button v-if="currentNotifications.length > counterNotifications && showMoreNotifications" 
                  variant="outline-primary"
                  :disabled="loadingNotifications"
                  @click="loadMoreNotifications">
                      <span v-if="!loadingNotifications">Cargar más</span>
                      <span v-if="loadingNotifications">Cargando <font-awesome-icon :icon="['fa', 'spinner']" class="fa-spin"/></span>
                  </b-button>
                  <span v-if="viewNoMatches">No se encontraron registros para mostrar</span>
                  <feather-icon v-if="showSpinnerRefreshNoti" icon="LoaderIcon" class="spinner m-1" size="2x"/>
                </div>
            </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import $ from 'jquery' 
export default {
  name: 'Viewer',
  data () {
    return {
      displayedNotification: {},
      displayedNotificationIndex: 0,
      notifications: [],
      notificationsList: [],
      notificationsRef: {},
      read:[],
      viewing: false,
      listing: false,
      onlyClose: false,
      country_id: null,
      company_id: null,
      company: null,
      marketplace_id: null,
      keyListing: 0,
      shipper_id: null,
      organization_id: null,
      isAdmin: false,
      lastCheckNotifications: 0,
      currentNotifications: [],
      counterNotifications: 7,
      size: 5,
      loadingNotifications: true,
      loading: true,
      keyFormRender: 0,
      fields: [],
      formFilter: {},
      optionsCategory: [
        {id: 'category_update', text: 'Actualización Condiciones', color: 'info'},
        {id: 'category_alert', text: 'Alerta Operacional', color: 'warning'},
        {id: 'category_new', text: 'Nuevo', color: 'success'}
      ],
      form: {},
      baseService: new BaseServices(this),
      keyFormRenderCheck: 0,
      fieldsCheck: [],
      formFilterCheck: {},
      optionsCheckbox: [
        {id: 'category', text: 'Categoría'}, 
        {id:'carrier', text:'Courier'},
        {id:'date', text:'Rango fecha'}
      ],
      keyFormRenderFilter: 0,
      fieldsFilter: [],
      activeBtnFilter: false,
      carriersFilterOrg: [],
      showCleanFilter: false,
      optionsCountries: [
        { id: 1, text: 'Chile', code: 'CL' },
        { id: 2, text: 'México', code: 'MX' },
        { id: 3, text: 'Colombia', code: 'CO' },
        { id: 4, text: 'Perú', code: 'PE' },
        { id: 5, text: 'Argentina', code: 'AR' },
        { id: 6, text: 'Ecuador', code: 'EC' }
      ],
      payloadFilter: {},
      viewNoMatches: false,
      showSpinnerRefreshNoti: false,
      showMoreNotifications: true,
      CONDITIONS: Object.freeze({
        PASARELA_PRO: 'pasarela-pro',
        PASARELA: 'pasarela'
      })
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      carriers: 'getCarriersNotifications'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    listing () {
      if (!!this.listing) $('#app').addClass('background-white')
      else $('#app').removeClass('background-white')
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData () {
      if (this.mySession.id) this.setInitialData()
    }, 
    setInitialData () {
      this.shipper_id = parseInt(this.$route.query.shipper_id || -1)
      this.organization_id = parseInt(this.$route.query.organization_id || -1)
      this.country_id = parseInt(this.$route.query.country || -1)
      this.marketplace_id = parseInt(this.$route.query.marketplace || -1)
      this.company_id = parseInt(this.$route.query.company || -1)
      this.isAdmin = this.company_id === -1 && this.marketplace_id === -1
      this.isSeller = (this.company_id !== -1 && this.shipper_id !== -1) && this.shipper_id !== this.organization_id
      if (!!this.isAdmin) this.optionsCheckbox.splice(1, 0, {id:'country', text:'País'})
      this.setField()
      this.getDataUser()
    },
    showPrev () { return !this.onlyClose && this.notifications.length > 1 && this.displayedNotificationIndex > 0 },
    showNext () { return !this.onlyClose && this.notifications.length > 1 && this.displayedNotificationIndex < this.notifications.length - 1 },
    showClose () { return this.onlyClose || this.displayedNotificationIndex === this.notifications.length - 1 },
    showCloseAdmin () { return !this.onlyClose && this.displayedNotificationIndex !== this.notifications.length - 1 },
    next () {
      const firebase = this.$root.$data
      if (!this.read.includes(this.displayedNotification.id)) {
        this.read.push(this.displayedNotification.id)
        this.seen.push(this.displayedNotification.id)
        //aumentar contador de lecturas
        firebase.notificationsCollection.doc(this.displayedNotification.id).update({views: firebase.incFB(1)})
      }
      this.displayedNotificationIndex++
      this.displayedNotification = this.notifications[this.displayedNotificationIndex]
    },
    prev () {
      this.displayedNotificationIndex--
      this.displayedNotification = this.notifications[this.displayedNotificationIndex]
    },
    finish () {
      const firebase = this.$root.$data
      if (!this.read.includes(this.displayedNotification.id)) {
        firebase.notificationsCollection.doc(this.displayedNotification.id).update({views: firebase.incFB(1)})
      }
      if (!this.read.includes(this.displayedNotification.id) || !this.arraysEqual(this.read, this.currentNotifications)) {
        this.read.push(this.displayedNotification.id)
        this.seen.push(this.displayedNotification.id)
        this.updateUserNotificationsRead()
      }
      parent.postMessage({madagascarCommand:'hideNotifications'}, '*')
    },
    arraysEqual (a, b) {
      return a.sort().toString() === b.sort().toString() 
    },
    stripHTML (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    setField () {
      this.fields = [
        {fieldType: 'FieldAutocomplete', name: 'search_notifications', searchOnType: { fx: this.searchNotifications, nChar: 3, debounce: 300, allowSearchAsValue: true, persistSearch: true }, placeholder:'Buscar por palabra clave', containerClass: 'col-10', icon: {icon: 'SearchIcon', size: '20', stroke: 2, color: '#1B4284'}, change: this.changeSearchNoti, noShowSpinner: true, clearable: true, noSeeClearable: true}
      ]
      this.fieldsCheck = [
        {fieldType: 'FieldCheckbox', multiple: true, name: 'filter_notifications', containerClass: 'col-md-12', options: this.optionsCheckbox, change: this.changeCheckbox}
      ]
      this.fieldsFilter = [
        { fieldType: 'FieldSelect', name: 'category', containerClass: 'hide', placeholder: 'Seleccione Categoría', options: this.optionsCategory, change: this.changeFilter, disabled: false},
        { fieldType: 'FieldSelect', name: 'country', containerClass: 'hide', placeholder: 'Seleccione País', change: this.changeFilter, disabled: false, options: this.optionsCountries, addFlags: true},
        { fieldType: 'FieldSelect', name: 'carrier', containerClass: 'hide', placeholder: 'Seleccione Courier', change: this.changeFilter, disabled: false},
        { fieldType: 'FieldDatepicker', name: 'date', containerClass: 'hide', range: true, disabled: false, change: this.changeFilter, clearable: true}
      ]
    },
    close () {
      parent.postMessage({madagascarCommand:'hideNotifications'}, '*')
    },
    openFromList (id) {
      $('.vs-dialog').addClass('full')
      $('body').addClass('overflow-hidden')
      parent.postMessage({madagascarCommand:'openNotificationsPopup'}, '*')
      this.displayedNotification = this.notificationsList.find(not => not.id === id)
      this.onlyClose = true
      this.listing = false
      this.viewing = true
      $('.router-content').removeClass('listing-notifications')
    },
    async getNotifications (ids) {
      let idsToGet = []
      let data = []
      let index = 0
      do {
        idsToGet = ids.slice((index * 10), 10 + (index * 10))
        const current = (await this.fb.notificationsCollection
          .where(this.fb.fb.firestore.FieldPath.documentId(), 'in', idsToGet).get()).docs
        data = [...data, ...current]
        index++
      } while ((index * 10) < ids.length)
      return data
    },
    async loadAll (idsNotifications) {
      //obtener todas, sólo usado cuando el usuario solicita una lista completa (clic en campanita)
      this.docs = await this.getNotifications(idsNotifications)
      this.filterShowListNotifications(this.docs)
      this.showMoreNotifications = true
      this.notificationsList = [...this.notificationsList, ...[...this.listAux].sort((a, b) => a.created < b.created ? 1 : -1)]
      localStorage.setItem('company_data', JSON.stringify(this.company))
      
      if (this.notificationsList.length === 0 || this.listAux.length === 0) {
        this.loadMoreNotifications()
      }
      this.loadingNotifications = false
    },
    filterCompanies (companies = []) {
      if (this.isAdmin) return true
      if (!Array.isArray(companies)) {
        if (companies.id) {
          return companies.id === this.shipper_id || companies.id === this.organization_id
        } else {
          return true
        }
      } else {
        if (companies.length === 0) return true
        return companies.findIndex(el => el.id === this.shipper_id || el.id === this.organization_id) !== -1
      }
    },
    filterShipper (shipper = []) {
      if (this.isAdmin) return true
      if (!!Array.isArray(shipper)) {
        if (shipper.length === 0) return true
        if (this.marketplace_id !== -1 || this.organization_id === this.shipper_id) return true
        else return shipper.findIndex(el => el.id === this.shipper_id) !== -1     
      }
    },
    filterCompaniesIds (companies = '') {
      if (this.isAdmin) return true
      if (companies !== '') {
        return companies.split(',').map(el => Number(el)).includes(this.shipper_id) || companies.split(',').map(el => Number(el)).includes(this.organization_id)
      } else {
        return true
      }
    },
    filterMarketplaces (companies = {}) {
      if (this.isAdmin) return true
      if (!this.shipper_id) {
        if (companies.id) {
          return companies.id === this.organization_id
        } else {
          return true
        }
      } else {
        return !companies.id
      }
    },
    filterCarriers (carriers = []) {
      if (this.isAdmin) return true
      if (carriers.length > 0) {
        if (this.carriersCompanyIDs && this.carriersCompanyIDs.length > 0) {
          return carriers.findIndex(el => this.carriersCompanyIDs.map(subel => subel).includes(el.id)) !== -1
        } else {
          return false
        }
      } else {
        return true
      }
    },
    filterType (type = {}) {
      if (this.isAdmin) return true
      if (this.shipper_id === -1) {
        if (type?.id) {
          return type.id === 'companies' || type.id === 'onlyCompanyId_marketplaces'
        } else {
          return true
        }
      } else if (this.shipper_id !== this.organization_id) {
        if (type?.id) {
          return type.id === 'onlyCompanyId_sellersByMarketplaces' || type.id === 'companies' || type.id === 'onlyCompanyId_companies'
        } else {
          return true
        }
      } else if (this.shipper_id === this.organization_id) {
        if (type?.id) {
          return type.id === 'companies' || type.id === 'onlyCompanyId_companies'
        } else {
          return true
        }
      }
    },
    filterExpiration (expiration) {
      if (expiration) {
        if (new Date().getTime() > new Date(expiration).getTime()) return false
      }
      return true
    },
    filterEnabling (enabling) {
      if (enabling) {
        if (new Date().getTime() < new Date(enabling).getTime()) return false
      }
      return true
    },
    filterAccount (account = null, carriers = []) {
      if (this.isAdmin) return true
      if (!!account) {
        if (this.carriersCompany && this.carriersCompany.length > 0) {
          if (carriers && carriers.length > 0) {
            const filterTypeAccountCarrier = this.carriersCompany.filter(item1 => 
              carriers.some(item2 => item1.carrier.id === item2.id && item1.contract_type === account.name)
            )
            return filterTypeAccountCarrier.length > 0
          } else {
            return this.carriersCompany.some(carrier => carrier.contract_type.toLowerCase() === account.name)
          }
        } else {
          return false
        }
      } else {
        return true
      }
    },
    filterBilling (billing = null) {
      if (this.isAdmin || !billing) return true
      const isDirectBilling = !!billing.id
      if (!!this.isSeller) {
        if (!!this.sellerBilling) {
          return this.sellerBilling.directBilling === billing.id
        } else {
          return false
        }
      }
      return isDirectBilling
    },
    isNotExcluded (ids, type = {}) {
      if (type?.id === 'onlyCompanyId_companies' && this.shipper_id && ids.split(',').map(el => Number(el)).includes(this.shipper_id)) return false
      if (type?.id === 'onlyCompanyId_companies' && this.shipper_id === -1 && ids.split(',').map(el => Number(el)).includes(this.organization_id)) return false
      if (type?.id === 'onlyCompanyId_sellersByMarketplaces' && ids.split(',').map(el => Number(el)).includes(this.organization_id) && this.shipper_id !== -1) return false
      if (type?.id === 'onlyCompanyId_allSellersMarketplaces' && this.shipper_id !== -1 && this.shipper_id !== this.organization_id) return false
      return true
    },
    async getDataUser () {
      this.read = [] 
      this.seen = [] 
      this.fb = await this.$root.$data
      const data = (await this.fb.accountsCollection.doc(this.$session.get('cas_user').id).get()).data()
      if (!!this.isAdmin) this.company = {id: 0}
      else this.company = this.company = data.organization
      this.read = data.notificationsRead || []
      this.seen = data.notificationsSeen || []
      this.lastCheckNotifications = data.lastCheckNotifications || 0
      if (!!this.isSeller) await this.getCompanyInfoBilling()
      if (!this.isAdmin) await this.getCarriersByCompany()
      else await this.getCarriers()
      this.initializeEverything()
    },
    async initializeEverything () {
      this.notifications = []
      $('body').addClass('overflow-hidden')

      //si ya habia leido alguna, omitir las que haya leido
      this.docs = []
      this.currentNotifications = (await this.fb.currentNotificationsCollection.doc('all').get()).data().data
      this.currentNotifications.reverse()
      const notifsToGet = this.currentNotifications.filter(el => !this.read.includes(el))
      if (notifsToGet.length > 0) {
        this.unfilteredDocs = await this.getNotifications(notifsToGet) 
      } else {
        this.unfilteredDocs = []
      }
      this.docs = this.unfilteredDocs.filter(doc => !this.read.includes(doc.id))
      let listAux = []
      this.docs.map(doc => listAux.push({id:doc.id, ...doc.data()}))
      listAux = listAux.filter((noti, index) => {
        localStorage.setItem(`__${index} expiration`, `${this.filterExpiration(noti.expiration)} ${JSON.stringify(noti.expiration)}`)
        localStorage.setItem(`__${index} enabling`, `${this.filterEnabling(noti.enabling)} ${JSON.stringify(noti.enabling)}`)
        localStorage.setItem(`__${index} companies`, `${this.filterCompanies(noti.companies)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.companies)}`)
        localStorage.setItem(`__${index} shipper_id`, `${this.filterShipper(noti.shipper_id)} ${this.shipper_id} ${JSON.stringify(noti.shipper_id)}`)
        localStorage.setItem(`__${index} marketplaces`, `${this.filterMarketplaces(noti.marketplaces)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.marketplaces)}`)
        localStorage.setItem(`__${index} carriers`, `${this.filterCarriers(noti.carriers)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.carriers)}`)
        localStorage.setItem(`__${index} country`, (!noti.country || (noti.country && ['*', this.country_id].includes(noti.country.id)) || this.country_id === -1))
        localStorage.setItem(`__${index} type`, `${this.filterType(noti.type)} ${JSON.stringify(noti.type)}`)
        localStorage.setItem(`__${index} exclude_type`, `${this.isNotExcluded(noti.exclude_onlyCompanyId, noti.exclude_type)} ${JSON.stringify(noti.exclude_type)} ${JSON.stringify(noti.exclude_onlyCompanyId)}`)
        localStorage.setItem(`__${index} account_type`, `${this.filterAccount(noti.account_type, noti.carriers)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.account_type)}`)
        localStorage.setItem(`__${index} direct_billing`, `${this.filterBilling(noti.direct_billing)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.direct_billing)}`)
        return (!noti.country || (noti.country && ['*', this.country_id].includes(noti.country.id)) || this.country_id === -1) && this.filterCompaniesIds(noti.onlyCompanyId) &&
        this.filterCompanies(noti.companies) && this.filterShipper(noti.shipper_id) && this.isNotExcluded(noti.exclude_onlyCompanyId, noti.exclude_type) && this.filterCarriers(noti.carriers) && this.filterType(noti.type) && this.filterExpiration(noti.expiration) && !noti.inactive && this.filterEnabling(noti.enabling) && this.filterAccount(noti.account_type, noti.carriers) && this.filterBilling(noti.direct_billing)
      })
      this.notifications = [...listAux].sort((a, b) => a.created < b.created ? 1 : -1)
      this.keyListing++
      this.displayedNotification = this.notifications[0]
      this.displayedNotificationIndex = 0

      if (this.notifications.length > 0) {
        this.viewing = true
        parent.postMessage({madagascarCommand:'openNotificationsPopup'}, '*')
      }
      this.notificationsList = []
      window.addEventListener('message', async event => {
        if (event.data.madagascarCommand === 'showList') {
          this.openList()
        }
      })
      
      parent.postMessage({madagascarCommand:'ready'}, '*')
    },
    async updateUserNotificationsRead () {
      this.$root.$data.accountsCollection.doc(this.$session.get('cas_user').id).update({notificationsRead: this.currentNotifications, notificationsShown: this.read, notificationsSeen: this.seen, lastCheckNotifications: new Date().getTime()})
    },
    async loadMoreNotifications () {
      this.loadingNotifications = true
      const prevValue = this.counterNotifications
      this.counterNotifications += this.size
      this.loadAll(this.currentNotifications.slice(prevValue, this.counterNotifications))
    },
    async openList () {
      if (this.notificationsList.length === 0) {
        this.counterNotifications = 7
        await this.loadAll(this.currentNotifications.slice(0, this.counterNotifications))
      }
      this.viewing = false
      this.listing = true
      $('.router-content').addClass('listing-notifications')
      $('body').removeClass('overflow-hidden')
      parent.postMessage({madagascarCommand:'openNotificationsList'}, '*')
    },
    getCarriersByCompany () {
      const queryParams = {}
      if (this.organization_id === this.shipper_id || this.shipper_id === -1) queryParams.organization_id = this.organization_id
      if (this.organization_id !== this.shipper_id && this.shipper_id !== -1) queryParams.shipper_id = this.shipper_id
      return this.baseService.callService('getCarriersOrganizationShippersNotifications', queryParams)
        .then(resp => {
          this.carriersFilterOrg = resp.data.map(el => {
            return {
              ...el.carrier,
              text: el.carrier.name
            }
          })
          const hash = {}
          const filterDuplicateCarriers = this.carriersFilterOrg.filter(o => hash[o.id] ? false : hash[o.id] = true)
          this.setSelectOptions('carrier', { options: filterDuplicateCarriers })
          const ids =  resp.data.map(el => (el.carrier.id))
          // Eliminar ids repetidos
          const idsArr = new Set(ids)
          const result = [...idsArr]
          this.carriersCompanyIDs = result
          this.carriersCompany = resp.data.map(item => {
            return {
              ...item,
              contract_type: item.contract_type === this.CONDITIONS.PASARELA_PRO ? this.CONDITIONS.PASARELA : item.contract_type
            }
          })
        })
        .catch(err => {
          return err
        })
    },
    getCarriers () {
      this.$store.dispatch('fetchService', {name: 'getCarriersNotifications', onSuccess: () => { this.setSelectOptions('carrier', { options: this.carriers }) }})
    },
    getCompanyInfoBilling () {
      return this.baseService.callService('getCompanyInfoBilling', null, { company_id: this.shipper_id })
        .then(resp => {
          this.hasBillignData(resp.data)
        })
        .catch(err => {
          console.error(err)
        })
    },
    hasBillignData(data) {
      if (!!data.hasBusinessData) {
        this.getBillingDataCompany()
      } else {
        this.sellerBilling = null
      }
    },
    getBillingDataCompany () {
      return this.baseService.callService('getManageBillingDataCompany', null, { company_id: this.shipper_id })
        .then(resp => {
          this.sellerBilling = resp.data
        })
        .catch(err => {
          this.sellerBilling = null
          console.error(err)
        })
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsFilter.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsFilter[index].options = options
        this.keyFormRender++
      }
    },
    changeCheckbox (name, value) {
      this.fieldsFilter.map(field => field.containerClass = 'hide')
      if (value.length > 0) {
        this.activeBtnFilter = true
        this.showCleanFilter = true
        value.map(item => {
          this.setSelectShow(item)
        })
      } else {
        this.activeBtnFilter = false
        this.showCleanFilter = false
      }
    },
    setSelectShow (value) {
      const index = this.fieldsFilter.findIndex(el => el.name === value?.id)
      if (index !== -1) {
        this.fieldsFilter[index].containerClass = 'col-9 mb-1 fields-filter-personalize' 
        this.keyFormRender++   
      } 
    },
    cleanFilter () {
      this.formFilterCheck = {}
      this.changeCheckbox('filter_notifications', this.formFilterCheck)
      this.payloadFilter = {}
      if (this.formFilter.date) {
        this.formFilter = {}
      } else {
        this.formFilter = {}
        this.resetNotifications()
      }
    },
    changeFilter (name, value) {
      this.showMoreNotifications = false
      this.showSpinnerRefreshNoti = true
      this.viewNoMatches = false
      if (value !== null) {
        this.payloadFilter[name] = value
      } else {
        delete this.payloadFilter[name]
      }
      if (name === 'country') {
        this.changeCountry(value)
      }
      this.getNotificationsFilters()
    },
    changeCountry (value) {
      const originalCarriers = this.setSelectOptions('carrier', { options: this.carriers })
      this.form.carriers = null
      if (value !== null) {
        const filterCarrierByCountry = this.carriers.filter(item => {
          return item.country_id === value.id
        })
        this.setSelectOptions('carrier', { options: filterCarrierByCountry })
      } else {
        return originalCarriers
      } 
    },
    formatterDateFilter (date) {
      const endString = date.end.toDateString()
      const startStirng = date.start.toDateString()
      const formatterEnd = `${endString} 24:00:00`
      const formatterStart = `${startStirng} 00:00:00`
      const parseEnd = Date.parse(formatterEnd)
      const parseStart = Date.parse(formatterStart)
      return {
        end: parseEnd, start: parseStart
      }
    },
    getNotificationsFilters () {
      if (Object.entries(this.payloadFilter).length > 0) {
        this.notificationsList = []
        this.ref = this.$root.$data.notificationsCollection
        if (this.payloadFilter.category) {
          this.ref = this.ref.where('category', '==', this.payloadFilter.category)
        }
        if (this.payloadFilter.country) {
          this.ref = this.ref.where('country', '==', this.payloadFilter.country)
        }
        if (this.payloadFilter.date) {
          const formatterDate = this.formatterDateFilter(this.payloadFilter.date)
          this.ref = this.ref
            .where('created', '>=', formatterDate.start)
            .where('created', '<=', formatterDate.end)
        }
        if (this.payloadFilter.carrier) {
          this.ref = this.ref.where('carriersCode', 'array-contains', this.payloadFilter.carrier.code)
        }
        this.ref
          .onSnapshot(resp => {
            if (resp.docs.length > 0) {
              this.showSpinnerRefreshNoti = false
              this.filterShowListNotifications(resp.docs) 
              this.notificationsList = [...this.notificationsList, ...[...this.listAux].sort((a, b) => a.created < b.created ? 1 : -1)]
            } else {
              this.viewNoMatches = true
              this.showSpinnerRefreshNoti = false
            }
            this.showMoreNotifications = false  
          })
      }
      else {
        this.resetNotifications()
      }
      
    },
    filterShowListNotifications (notifications) {
      this.listAux = []
      notifications.map(doc => this.listAux.push({id:doc.id, ...doc.data(), text:doc.data().title, created_date: this.$options.filters.moment(new Date(doc.data().created), 'YYYY-MM-DD HH:mm:ss'), cleanContent: this.stripHTML(doc.data().content.replace(/></g, '> <'))}))
      this.listAux = this.listAux.filter((noti, index) => {
        localStorage.setItem(`__${index} expiration`, `${this.filterExpiration(noti.expiration)} ${JSON.stringify(noti.expiration)}`)
        localStorage.setItem(`__${index} enabling`, `${this.filterEnabling(noti.enabling)} ${JSON.stringify(noti.enabling)}`)
        localStorage.setItem(`__${index} companies`, `${this.filterCompanies(noti.companies)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.companies)}`)
        localStorage.setItem(`__${index} shipper_id`, `${this.filterShipper(noti.shipper_id)} ${this.shipper_id} ${JSON.stringify(noti.shipper_id)}`)
        localStorage.setItem(`__${index} marketplaces`, `${this.filterMarketplaces(noti.marketplaces)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.marketplaces)}`)
        localStorage.setItem(`__${index} carriers`, `${this.filterCarriers(noti.carriers)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.carriers)}`)
        localStorage.setItem(`__${index} country`, (!noti.country || (noti.country && ['*', this.country_id].includes(noti.country.id)) || this.country_id === -1))
        localStorage.setItem(`__${index} type`, `${this.filterType(noti.type)} ${JSON.stringify(noti.type)}`)
        localStorage.setItem(`__${index} exclude_type`, `${this.isNotExcluded(noti.exclude_onlyCompanyId, noti.exclude_type)} ${JSON.stringify(noti.exclude_type)} ${JSON.stringify(noti.exclude_onlyCompanyId)}`)
        localStorage.setItem(`__${index} account_type`, `${this.filterAccount(noti.account_type, noti.carriers)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.account_type)}`)
        localStorage.setItem(`__${index} direct_billing`, `${this.filterBilling(noti.direct_billing)} ${this.company_id} ${this.marketplace_id} ${JSON.stringify(noti.direct_billing)}`)
        return (!noti.country || (noti.country && ['*', this.country_id].includes(noti.country.id)) || this.country_id === -1) && this.filterCompaniesIds(noti.onlyCompanyId) &&
        this.filterCompanies(noti.companies) && this.filterShipper(noti.shipper_id) && this.filterType(noti.type) &&
        this.isNotExcluded(noti.exclude_onlyCompanyId, noti.exclude_type) && this.filterCarriers(noti.carriers) && this.filterEnabling(noti.enabling) && this.filterAccount(noti.account_type, noti.carriers) && this.filterBilling(noti.direct_billing)// && this.filterExpiration(noti.expiration) && !noti.inactive
      })
      this.showSpinnerRefreshNoti = false
    },
    async resetNotifications () {
      this.notificationsList = []
      this.viewNoMatches = false
      this.showMoreNotifications = false
      this.showSpinnerRefreshNoti = true
      this.currentNotifications = (await this.fb.currentNotificationsCollection.doc('all').get()).data().data
      this.currentNotifications.reverse()
      this.openList()
    },
    deleteDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    searchNotifications (value) {
      const formatterValue = this.deleteDiacritics(value)
      this.fields[0].icon = {icon: 'LoaderIcon', size: '20', stroke: 2, color: '#1B4284'}
      const splitKeywords = formatterValue.toLowerCase().split(' ')
      this.ref = this.$root.$data.notificationsCollection
      this.ref = this.ref.where('keywordsArray', 'array-contains-any', splitKeywords)
      return this.ref.get()
        .then((resp) => {
          this.filterShowListNotifications(resp.docs) 
          this.fields[0].icon = {icon: 'SearchIcon', size: '20', stroke: 2, color: '#1B4284'}
          return this.listAux
        })
        .catch((error) => {
          console.error('Error getting document:', error)
        })
    },
    changeSearchNoti (name, value) {
      if (!!value) {
        this.fields[0].noSeeClearable = false
        this.notificationsList = []
        this.notificationsList.push(value) 
        this.showMoreNotifications = false
      } else {
        this.fields[0].noSeeClearable = true
        this.resetNotifications()
      }    
    }
  }
}
</script>
<style lang="scss">
.notifications-container-viewing {
    padding: 2.2rem;
    width: 100%;
    top:0px;
    height: 100%;
    .scrollable-notification {
      // width: 1130px;
      height: 635px;
      max-height: calc(100vh - 100px);
      max-width: calc(100vw - 5%);
      overflow: scroll;
      img {
        max-width: calc(100vw - 5%);
      }
    }
}
.notifications-container-listing {
    // width: 440px;
    background-color: #fff;
}
.flex-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.8rem 1.4rem;
    border-bottom: 1px solid #1B4284;
}
.flex-header h5 {
    text-align: center;
    width: 100%;
    align-self: center;
    color: #1B4284;
    font-weight: 700;
    font-size: 18px;
    line-height: 13px;
}
.flex-header button {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    opacity: 1;
    margin-bottom: 1rem;
}
.notification-list {
  margin: 0;
  padding: 0;
}
.notification-list li {
  list-style: none;
  background: #F0F7FA;
  box-shadow: 0px 10.9243px 16.3865px rgba(0, 0, 0, 0.14), 0px 4.09662px 20.4831px rgba(0, 0, 0, 0.12), 0px 5.46216px 6.8277px rgba(0, 0, 0, 0.2);
  border-radius: 19.1176px;
  margin: 1.5rem 1rem 2rem 1rem;
  padding: 1rem;
}
.notification-list h2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #5E7B91;
}
.notification-list p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 10;
    overflow: hidden;
    line-height: 19px;
    color: #4E6678;
}
.personalize-badge {
  padding: 0.7rem;
  margin-right: 0.5rem;
}
.personalize-created {
  color: #90A6B7;
  font-weight: 400;
  font-size: 10px;
}
.mb-badge {
  margin-bottom: 0.5rem;
}
.personalize-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.personalize-footer p {
  font-weight: 500;
  line-height: 14px;
  color: #1B4284;
  margin: 0;
}
.img-logos-width {
  width: 110px;
  // height: 22.97px;
}
#dropdown-filter button {
  padding: 0.5rem;
  color: #d8d6de;
  border-color: #d8d6de;
  margin-right: 0.5rem;
}
.filter-notifications {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.styles-flags {
  width: 30px;
  margin-right: 0.5rem;
  border-radius: 5px;
}
.flex-flags-admin {
  display: flex;
  align-items: center;
}
.flex-btn-more-noti {
  display: flex;
  justify-content: center;
}
.flex-btn-more-noti button {
  width: 88%;
  border-radius: 19.1176px;
}
.flex-btn-more-noti span {
  margin: 1rem;
  padding: 1rem;
}
.show-modal-noti:hover {
  cursor: pointer;
}
.actions {
  z-index: 999999;
  .vs-button-primary.vs-button-filled {
    min-width: 120px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    float: right;
    //background-color: #3cbb6e !important;
    border-radius: 0;
    margin-top: 6px;
    margin-bottom: 0;
    // margin-right: 7px;
    //color: white !important;
    background-color: #28c73c  !important;
    color: white !important;
    &.prev { background-color: white !important; color: black !important; }
  }
  position: fixed;
  bottom:0;
  left:0;
  width: 100%;
  background: #7a7a7a;
}
.width-flags{
  width: 1.5rem;
}
.flex-flags{
  display: flex;
  justify-content: flex-end;
}
.padding-top-popup{
  padding-top: 0.75rem!important;
}

.scrollable-notification::-webkit-scrollbar {
  -webkit-appearance: none;
}
.scrollable-notification::-webkit-scrollbar:vertical {
  width: 11px;
}
.scrollable-notification::-webkit-scrollbar:horizontal {
  height: 11px;
}
.scrollable-notification::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.scrollable-notification::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}
.dark-light-badge {
  background-color: #969696;
}
.center-button-all-close {
  position: absolute;
  right: 43%;
}
.field-filter form {
  margin-right: -2.5rem !important;
}
.hide {
  display: none;
}
#date {
  margin-bottom: 0px !important;
}
.fields-filter-personalize {
  padding-left: 0 !important;
}
.fields-filter-personalize .vs__dropdown-toggle, .fields-filter-personalize .container-range-input {
  border: #1B4284 1px solid !important;
}
.show > .color-personalize-btn-filter, .active > .color-personalize-btn-filter {
    background-color: #1B4284 !important;
    border-color: #1B4284 !important;
}
.color-personalize-btn-filter:hover {
    background-color: #1B4284 !important;
}
.list-options-filter {
  border: 1.5px solid #1B4284 !important;
  z-index: 100;
}
.personalize-form {
  padding: 0.5rem;
}
.margin-select-filters{
  margin-top: 1rem;
  margin-left: 2rem;
}
#search_notifications .clean-input {
  right: 4rem;
}
.background-white {
  background: #fff;
}
.styles-img-carriers {
  max-width: 90px;
  max-height: 60px;
}
@media (max-width: 768px) {
  .float-mobile-left, .center-button-all-close {
    float: left;
    margin: 0.2rem;
  }
  .center-button-all-close {
    position: initial !important;
  }
}
</style>